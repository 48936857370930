import React, { Component } from 'react'
import { Link } from 'gatsby'

import logo from '../../images/logo-alt.png'

export default class Navbar extends Component {
  state = {
    navbarOpen: false,
    css: 'collapse navbar-collapse',
    links: [
      {
        id: 1,
        path: '/',
        text: 'Home',
      },
      {
        id: 2,
        path: '/wie-zijn-wij',
        text: 'Wie zijn wij?',
      },
      {
        id: 3,
        path: '/stoornissen',
        text: 'Stoornissen',
      },
      {
        id: 4,
        path: '/behandeltraject',
        text: 'Behandeltraject',
      },
      {
        id: 5,
        path: '/contact',
        text: 'Contact',
      },
      {
        id: 6,
        path: '/blog',
        text: 'Blog',
      },
      {
        id: 7,
        path: '/vacatures',
        text: 'Vacatures',
      }
    ],
  }

  // methods
  navbarHandler = () => {
    this.state.navbarOpen
      ? this.setState({ navbarOpen: false, css: 'collapse navbar-collapse' })
      : this.setState({
          navbarOpen: true,
          css: 'collapse navbar-collapse show',
        })
  }

  render() {
    return (
      <nav className="navbar navbar-expand-sm nav-dark navbar-dark">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={this.navbarHandler}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className={this.state.css}>
          <ul className="navbar-nav mx-auto">
            {this.state.links.map(link => {
              return (
                <li key={link.id} className="nav-item">
                  <Link className="nav-link text-uppercase" to={link.path}>
                    {link.text}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </nav>
    )
  }
}
