import React from 'react'
import { Link } from 'gatsby'

export default function Footer() {
  return (
    <footer className="footer text-center">
      <div className="container">
        <div className="row">
          <div className="footer__links col-12">
            <Link to="/privacy" className="footer__link">
              Privacy
            </Link>

            <a
              href="https://www.logopedie.nl/paginas/openbaar/klachten/klachten"
              target="_blank"
              className="footer__link"
              rel="noopener noreferrer"
            >
              Klachtenregeling
            </a>
          </div>
        </div>
        <div className="row">
          <div className="copy col-12">
            &copy; {new Date().getFullYear().toString()} Alle Rechten
            Voorbehouden
            <span className="copy--small">
              designed with love by @Edwin Boon
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
